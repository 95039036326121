<template>
  <div class="wrap">
    <Navbar :title="$t('blindbox.navigator')">
    </Navbar>
    <div class="turntable">
      <canvas id="win_wrap" :width="width" :height="height"></canvas>
      <div class="bg">
        <div class="btn" @click="handleSubmit">{{ $t("blindbox.submit") }}</div>
        <div class="draw_num">{{ $t("blindbox.remainTimes", { __: remainTimes || 0 }) }}</div>
      </div>
    </div>
    <!-- <div class="top_bg">
      <div class="content_wrap">
        <div class="times">
          {{ $t("blindbox.remainTimes", { __: remainTimes || 0 }) }}
        </div>
        <div class="lottery" @click="handleSubmit">
          {{ $t("blindbox.submit") }}
        </div>
      </div>
    </div> -->
    <div class="bottom_bg">
      <!-- <div class="integral_bar">
        <div class="integral_text">Điểm số của tôi</div>
        <div class="integral_times">{{ userInfo.point }}</div>
      </div> -->
      <div class="padding">
        <van-grid square :column-num="3" :gutter="10" icon-size="30px" :style="{ 'justify-content': 'center' }">
          <van-grid-item :icon="item.icon" :text="$t(item.text)" v-for="(item, index) in tools" :key="index"
            @click="item.fun"></van-grid-item>
        </van-grid>
        <div class="marquee_wrap">
          <van-icon class="icon" :color="verConfig.noticeIconColor || '#fcb000'" name="volume" size="28" />
          <marquee behavior="scroll" direction="up" class="marquee" scrollamount="3">
            <div v-for="(item, index) in blindNotice" class="halloffame" :key="index">
              <div class="notice-item-wrap">
                <div v-html="item.text">
                </div>
                <!-- <span>
                  Chúc mừng khách hàng
                  <span class="spancolor"> {{ item.name }} </span>&nbsp;trúng
                  thưởng&nbsp;<span class="spancolor">
                    {{ item.prize }}
                  </span>
                </span> -->
              </div>
            </div>
          </marquee>
        </div>
        <!-- 奖品列表 -->
        <!-- <template>
          <div class="prize_title">Danh sách trúng thưởng</div>
          <ListItem
            v-for="(item, index) in prizeArr"
            :key="`prize_${index}`"
            :data="item"
            type="PRIZE"
          />
        </template> -->
        <!-- 兑奖列表 -->
        <!-- <template>
          <div class="point_title">Danh sách đổi điểm thưởng</div>
          <ListItem
            v-for="(item, index) in redemptionArr"
            :key="`point_${index}`"
            :data="item"
            type="POINT"
          />
        </template> -->
      </div>
    </div>
    <div class="overlay" @click.stop="showGiftInfo = false" v-if="showGiftInfo">
      <div class="content" @click.stop="() => { }">
        <div class="top_title_wrap">
          <div class="top_title">
            <!-- {{ $t("blindbox.goodLuck") }} -->
            JACKPOT
          </div>
        </div>
        <!-- <img :src="$img(
          tempGiftInfo.item.picture[0]
            ? tempGiftInfo.item.picture[0].path
            : ''
        )
          " alt="" /> -->
        <div class="text">
          <div class="win_title">
            <div>{{ $t("blindbox.win_title1") }}</div>
            <div>{{ $t("blindbox.win_title2") }}</div>
          </div>
          <span class="win_value">
            {{ tempGiftInfo.winTitle || "" }}
          </span>
        </div>
        <div class="btn_confirm" @click.stop="showGiftInfo = false">
          {{ $t("btn.confirm") }}
        </div>
      </div>
    </div>
    <Dialog v-model="showRule" :message="rule" :confirm-button-text="this.$t('btn.confirm')"></Dialog>
  </div>
</template>

<script>
import { isLinkGame, isGameGoLink, $get, $post } from "@/utils/request.js";
// import { userinfo } from "@/api/use.js";
import { Dialog, Toast, Grid, GridItem, Icon } from "vant";
import ListItem from "./components/list_item.vue";
import { mapGetters } from "vuex";
import { $post as post, $get as get, dealError } from "@/utils";
import Navbar from "@/components/JNav";
import dataDetail from "@/utils/dataDetail";
import winWheel from "@tt/utils/src/Winwheel"

function randName() {
  let name =
    String(dataDetail.rand(72, 99)) +
    dataDetail.rand(3, 9) +
    "****" +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10);
  return name;
}
export default {
  components: { ListItem, [Grid.name]: Grid, [GridItem.name]: GridItem, [Icon.name]: Icon, Navbar, Dialog: Dialog.Component },
  data() {
    this.tools = [
      {
        icon: require("./images/icon_rule.png"),
        // text: "Luật xổ số",
        text: 'blindbox.rule',
        fun: () => {
          this.showRule = true;
          // Dialog.alert({ message: this.rule, confirmButtonText: this.$t("btn.confirm") });
        },
      },
      {
        icon: require("./images/icon_service.png"),
        text: "blindbox.service",
        fun: () => {
          // location.href = this.serviceURL;
          this.$store.dispatch("toService");
        },
      },
      // {
      //   icon: require("./images/icon_record.png"),
      //   text: "Lịch sử trúng thưởng",
      //   fun: () => {
      //     this.$router.push("/WinningRecord");
      //   },
      // },
    ];
    return {
      width: 0,
      height: 0,
      userInfo: {},
      serviceURL: "",
      prizeArr: [],
      redemptionArr: [],
      rule: "",
      showRule: false,
      showGiftInfo: false,
      remainTimes: 0,
      tempGiftInfo: {
        // title: "测试",
        // item: {
        //   picture: [
        //     {
        //       path: "uploads/picture/202101/a2f31404cf255833712852c6577245ac.png",
        //     },
        //   ],
        // },
      },
      isShake: false, // 加载flag
      blindNotice: [],
      canvasWrap: null,
      init: false,
      winMsg: ""
    };
  },
  computed: {
    // ...mapGetters(["blindNotice"]),
  },
  created() {
    this.getUserInfo();
    // this.getService();
    // this.getRecords();
    this.getInitData();
  },
  mounted() {
    this.width = (screen.width * 300) / 390;
    // this.height = (this.width * 300) / 390;
    this.height = this.width;
    const _this = this;
    const radiusSize = this.height / 2;
    this.canvasWrap = new winWheel({
      canvasId: "win_wrap",
      numSegments: 0,
      // 'drawMode' : 'image',
      segments: [],
      outerRadius: radiusSize,
      rotationAngle: 0,
      textFontWeight: 500,
      textFontSize: 14,
      textFillStyle: "#fff",
      textDirection: "reversed",
      centerX: radiusSize + 2,
      centerY: radiusSize,
      strokeStyle: "transparent",
      animation: {
        type: "spinToStop",
        duration: 5,
        spins: 8,
        callbackFinished: _this.stop,
      }
    });
  },
  methods: {
    initChestNotice(odds) {
      const _this = this;
      const res = [];
      for (let i = 0; i < 128; i++) {
        const name = randName();
        const prizeIndex = dataDetail.rand(0, odds.length - 1);
        res.push({
          name,
          prize: odds[prizeIndex].title,
          // text: `Chúc mừng khách hàng ${name} trúng thưởng ${odds[prizeIndex].title}`
          text: _this.$t('blindbox.noticeText', { __: `<span style="color: #fcb000;">${name}</span>`, ___: `<span style="color: #fcb000;">${odds[prizeIndex].title}</span>` })
        })
      }
      this.blindNotice = res;
    },
    async getInitData() {
      try {
        const { data: { rule, userNum, user_win_money, bet_win_money, odds } } = await get("/marketing/xydzp");
        this.rule = rule;
        this.remainTimes = userNum;
        const _odds = [...odds];
        this.prizes = [...odds];
        // this.records = userLists || [];
        this.initChestNotice(odds || []);
        if (!this.init) {
          for (let i = 0, len = odds.length; i < len; i++) {
            const item = _odds[i];
            this.canvasWrap.addSegment(
              {
                text: item.title,
                fillStyle: item.color,
                strokeStyle: item.color
              },
              1
            );
          }
          this.canvasWrap.numSegments = odds.length;
          this.canvasWrap.draw();
          this.init = true;
        }
      } catch (error) {
        dealError(error)
      }
    },
    refresh() {
      // this.getRecords();
      this.getUserInfo();
    },
    dialog(msg, callback = () => { }) {
      Dialog.confirm({
        message: msg,
        confirmButtonText: "xác nhận",
        showCancelButton: false,
      }).then(() => {
        callback();
      });
    },
    async getUserInfo() {
      this.$api.getUserInfo((res) => {
        this.userInfo = res;
      })
      // try {
      //   const { data } = await userinfo();
      //   const res = await this.verifyRes(data);
      //   this.userInfo = res;
      // } catch (error) {
      //   console.error("获取用户信息失败:", error);
      // }
    },
    async getService() {
      const {
        data: { data, ret },
      } = await $get({
        url: "other/config",
        params: {
          act: "system",
        },
      });
      if (ret === 1) {
        this.serviceURL = data.service_url;
        localStorage.setItem("appurl_android", data.appurl_android);
      }
    },
    verifyRes({ ret, msg, data }) {
      return new Promise((resolve, reject) => {
        if (ret == -1) {
          return this.$router.replace("/login");
        }
        if (ret == 1) {
          resolve(data);
          return;
        }
        Toast(msg);
      });
    },
    delay() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 3000);
      });
    },
    action(angle) {
      if (!!this.canvasWrap.rotationAngle) {
        this.canvasWrap.rotationAngle = 0;
        this.canvasWrap.draw();
      }
      this.canvasWrap.animation.stopAngle = angle || 0;
      this.canvasWrap.startAnimation();
    },
    stop(e) {
      // this.dialog(this.winMsg);
      this.showGiftInfo = true;
    },
    async handleSubmit() {
      if (this.isShake) return;
      Toast.loading({ duration: 0 });
      this.isShake = true;
      try {
        const [delayRes, { data, msg }] = await Promise.all([
          this.delay(),
          // $post("/marketing/gift", {
          //   act: "user_gift",
          //   type: "point",
          // }),
          $post("/marketing/xydzp", { act: "start" })
        ]);
        this.isShake = false;
        Toast.clear();
        this.winMsg = msg;
        this.verifyRes(data).then((res) => {
          this.tempGiftInfo = res || {};
          this.action(res.rotate);
          // this.showGiftInfo = true;
          this.remainTimes = res.userNum;
          this.refresh();
        });
      } catch (error) {
        this.isShake = false;
        Toast.clear();
        console.error("抽奖错误:", error);
      }
    },
    // async getRecords() {
    //   // this.$store.dispatch("getGiftConfig", "test");
    //   try {
    //     const { data } = await $get("/marketing/gift", {
    //       act: "list",
    //       type: "point",
    //     });
    //     this.verifyRes(data).then(({ setting: res }) => {
    //       this.prizeArr = res.odds || [];
    //       this.redemptionArr = res.goods || [];
    //       this.rule = res.rule || "";
    //     });
    //   } catch (error) {
    //     console.error("获取中奖列表错误:", error);
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  font-size: calc(32rem / 2 / 16);
  @top_height: calc(100vw / 750 * 800);
  min-height: 100vh;
  background-size: 100% auto;
  background-image: url("./images/bottom_bg.png");
  background-repeat: no-repeat;
  @textTheme: #fba70d;
  /deep/ .van-dialog{
    @background: #074669;
    @textColor: #f38606;
    background-color: @background;
    .van-dialog__message{
      // color: var(--textColor);
      color: @textColor;
    }
    .van-button--default{
      background-color: @background;
      // color: var(--textColor);
      color: @textColor;
    }
  }
  .turntable {
    margin-top: calc(50rem / 16 / 2);
    position: relative;
    padding-top: calc(164rem / 16 / 2);
    padding-bottom: calc(180rem / 16 / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .bg {
      width: 100vw
        /* 410/16 */
      ;
      height: calc(100vw * 468 / 390)
        /* 477/16 */
      ;
      background: url("../../assets/draw/turntable_bg.png");
      background-size: 100% 100%;
      // margin-left: calc(10rem / 16 / 2);
      position: absolute;
      top: 0
      // -0.4375rem
        /* 7/16 */
      ;
      left: 0
      // -1.0625rem
        /* 17/16 */
      ;

      .btn {
        width: calc(280rem / 16 / 2);
        white-space: nowrap;
        // overflow: hidden;
        line-height: calc(100vw / 390 * 45);
        font-family: TeXGyreAdventor-Regular;
        // font-size: calc(67rem / 16 / 2);
        font-size: calc(30rem / 16 / 2);
        font-weight: 700;
        font-stretch: normal;
        letter-spacing: calc(1rem / 16 / 2);
        color: #fffc00;
        text-align: center;
        position: absolute;
        bottom: calc(100vw / 390 * 78);
        left: 50%;
        margin-left: calc(10rem / 16 / 2);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        &:active {
          opacity: 0.8;
        }
      }

      .draw_num {
        // color: var(--textColor);
        color: var(--light);
        font-size: calc(30rem / 16 / 2);
        font-family: AdobeHeitiStd-Regular;
        position: absolute;
        width: -webkit-max-content;
        width: max-content;
        bottom: calc(40rem / 16 / 2);
        line-height: calc(70rem / 16 / 2);
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }

  .top_bg {
    width: 100vw;
    height: @top_height;
    background-image: url("./images/top_bg.png");
    position: relative;
    background-size: 100% 100%;

    .content_wrap {
      position: absolute;
      width: 100vw;
      height: calc(190rem / 2 / 16);
      bottom: calc(20rem / 2 / 16);

      .times {
        font-size: calc(38rem / 2 / 16);
        color: #ffc600;
        text-align: center;
        padding-top: calc(10rem / 2 / 16);
      }

      .lottery {
        @height: calc(100vw / 750 * 137);
        width: calc(100vw / 750 * 400);
        height: @height;
        line-height: calc(@height - calc(20rem / 2 / 16));
        background-size: 100% 100%;
        background-image: url("./images/btn_bg.png");
        margin: 0 auto 0;
        text-align: center;
        color: #f00;
        font-size: calc(42rem / 2 / 16);

        &:active {
          transform: translateY(calc(6rem / 2 / 16));
        }
      }
    }
  }

  .bottom_bg {
    min-height: calc(100vh - @top_height);
    background-size: 100% auto;
    // background-image: url("./images/bottom_bg.png");
    padding-bottom: calc(20rem / 2 / 16);
    overflow: hidden;

    .integral_bar {
      @height: calc(150rem / 2 / 16);
      width: 60vw;
      height: @height;
      background-image: url("./images/integral.png");
      color: var(--textColor);
      font-size: calc(100vw / 1080 * 54);
      background-size: 100% 100%;
      margin: calc(30rem / 2 / 16) auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .padding {
      margin: 0 calc(30rem / 2 / 16);

      /deep/ .van-grid-item__content {
        background: transparent;

        &::after {
          border: none;
        }
      }

      /deep/ .van-grid-item__text {
        white-space: nowrap;
        color: #fcb000;
      }

      .prize_title {
        color: var(--textColor);
        font-size: calc(36rem / 2 / 16);
      }

      .point_title {
        .prize_title();
        margin-top: calc(40rem / 2 / 16);
      }
    }
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    @padding: calc(20rem / 2 / 16);
    z-index: 999;

    .content {
      background-image: url("./images/success_bg.png");
      background-size: 100% 100%;
      @top: calc(687rem / 2 / 16);
      padding: @top calc(140rem / 2 / 16) 0 calc(170rem / 2 / 16);
      height: calc(1180rem / 2 / 16 - @top); // 总高calc(1180rem / 2 / 16)
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .top_title_wrap{
        position: relative;
        width: 100%;
        .top_title{
          // color: var(--textColor);
          color: @textTheme;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: calc(-55rem / 16);
          line-height: calc(28rem / 16);
        }
      }

      .text {
        // font-size: calc(19rem / 16);
        color: @textTheme;
        // margin-top: calc(15rem / 16);
        height: calc(170rem / 16);
        // display: flex;
        // align-items: center;
        text-align: center;
        color: #FB5353;
        font-size: calc(40rem / 16);
        font-weight: 800;
        .win_title{
          color: #F46DD6;
          font-size: calc(30rem / 16);
        }
      }

      >img {
        @size: calc(132rem / 16);
        width: @size;
        height: @size;
        background-color: #ffffff;
        border-radius: calc(20rem / 2 / 16);
        margin-top: calc(30rem / 2 / 16);
      }

      .btn_confirm {
        position: absolute;
        padding: calc(30rem / 2 / 16) calc(80rem / 2 / 16);
        // background: #71D249;
        background-image: url("./images/success_btn_bg.png");
        background-size: 100% 100%;
        bottom: calc(-1 * 60rem / 2 / 16);
        border-radius: calc(20rem / 2 / 16);
        // color: var(--textColor);
        color: var(--light);
        box-shadow: 0px 4px 6px 0px rgba(0, 46, 3, 0.85);
      }
    }
  }
}

.marquee_wrap {
  display: flex;
  // background-color: #fff;
  padding: 12px 4vw 12px 4vw;
  box-shadow: 0 0.533vw 1.333vw 0 rgba(255, 255, 255, 0.1);
  align-items: center;
  background-image: url("./images/list_item.png");
  background-size: 100% 100%;
  margin-bottom: 36px;
  color: var(--textColor);
  border: 1px solid #fff;
  border-radius: 20px;

  .spancolor {
    color: #fcb000;
  }

  .icon {
    font-size: 36px;
    margin-right: 28px;
  }
}

// 轮播
.marquee {
  // width: 85%;
  // margin: 0 auto !important;
  // margin-top: 20px;
  // height: calc(34rem / 16);
  height: 3em;
  // margin-left: 5vw;
  border-radius: 1vw;

  .halloffame {
    // padding: 0 9vw;
    font-size: 12px;
    line-height: 3em;
    margin-top: 2em;
    color: var(--light);
  }
}
</style>
